import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { TransitionState } from 'gatsby-plugin-transition-link'

import Layout from '@components/Layout'
import { LAYOUT_STYLE } from '../Layout/constants'
import { richTextOptions } from '@components/Markdown'
import styles from './CAPrivacyPolicy.module.scss'
const cx = classNames.bind({ ...styles })

const CAPrivacyPolicy = ({ data }) => {
  const buildContent = node => {
    return documentToReactComponents(node, richTextOptions)
  }

  const rawContent = data.allContentfulLegalPageCaPrivacyPolicy.edges[0].node
  const heading = rawContent.heading
  const title = ""
  const content = buildContent(rawContent.content.json)

  const links = [
    {
      label: title,
      path: '/caprivacypolicy',
      active: false,
    },
  ]

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => (
        <Layout
          className={transitionStatus}
          layoutStyle={LAYOUT_STYLE['topNav']}
          seo={{ title }}
          links={links}
          heading={heading}
        >
          <div className={cx('richTextContent')}>
            {content}
            <a href="//assets.ctfassets.net/mw8dq0fsj7wk/52CXwNrHetUfHQWEMnITkU/3a4543c75f6a17212683a6fa0e6c7542/caprivacypolicy.pdf">California Privacy Policy Notice</a>
          </div>
        </Layout>
      )}
    </TransitionState>
  )
}

CAPrivacyPolicy.propTypes = {
  data: PropTypes.object,
}

export default CAPrivacyPolicy
