import CAPrivacyPolicy from '@components/CAPrivacyPolicy'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    allContentfulLegalPageCaPrivacyPolicy {
      edges {
        node {
          title
          heading
          content {
            json
          }
        }
      }
    }
  }
`

export default CAPrivacyPolicy
